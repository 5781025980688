<template>
	<v-app id="change_password" >
  <v-main style="background-color:#D5D8DC" >
    <v-container  fill-height >
      <v-layout align-center justify-center>
        <v-flex style="max-width:650px" class='text-center display-1 mb-3'>
          <v-card dark color="white" elevation="6">
            <v-card-text class="subheading">
              <v-img
                  class = 'mx-auto mt-4'
                  max-height="45"
                  max-width="181"
                  src="@/media/logo_azul.png"
                  transition="fade"
              ></v-img>
                <br>
                <br>

                <v-alert
			        outlined
			        type="success"
			        text
			      >
			        La cuenta asociada a <b>{{email}}</b> ha sido activada correctamente, se ha enviado un enlace para crear la contraseña.
			      </v-alert>  
            </v-card-text>
          </v-card>
          
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
 </v-app>
</template>

<script>
const API_URL = 'https://api.datari.net/auth/request-reset-email/';
import axios from 'axios';

export default {

  name: 'verify_email',
  props: ['email'],

  data () {
    return {

    }
  },
  mounted(){
  	this.reset();
  },
  methods :{
  	reset() 
    {
        if(this.email != null){
            let email = this.email;
            axios({
                method: 'post',
                url: API_URL,
                data: {
                    email: email,
                    redirect_url: 'https://beta.datari.net/change_password/'
                }
            });
            console.log('correo_enviado')
        }

    },
  }
}
</script>

<style lang="css" scoped>
</style>