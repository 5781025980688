<template>
	<v-app id="index" >
  	<v-main style="background-color:#1565C0" >
  		<v-container  fill-height >
      	<v-layout align-center justify-center>
        	<div>
	        	<div>
	        		<v-img
	              max-height="50"
	              max-width="220"
	              src="@/media/logo_blanco.png"
	              transition="fade"
	            />
		        </div>
           	<div class="mt-8 mb-14 text-center">
           		<v-progress-circular
			        	class="mt-8"
			        	:size="60"
			        	color="white"
			        	indeterminate
			      	></v-progress-circular>
          	</div>
          	<div class="mt-8 text-center">
            	<div style="margin-left:45%">
        				<v-img
	            		max-height="75"
	           	 		max-width="45"
	            		src="@/media/logo_lab_2.png"
	            		transition="fade"
	            	/>
            	</div>
          	</div>
          </div>
        </v-layout>
      </v-container>
  	</v-main>
  </v-app>
</template>

<script>
export default {

  name: 'indexView',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>