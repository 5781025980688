import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Reset from '../views/reset_password.vue';
import ChangePassword from '../views/change_password.vue';
import Verify from '../views/verify_email.vue';
import store from '../store/index.js';
import Index from '../views/indexView.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/index',
    name:'index',
    component: Index 
  },
  {
    path: '/reset_password',
    name:'reset_password',
    component: Reset
  },
  {
    path: '/verify_email',
    name:'verify_email',
    component: Verify,
    props: (route) => ({
      email: route.query.email,
    })
  },
  {
    path: '/change_password',
    name: 'change_password',
    component: ChangePassword,
    props: (route) => ({
        token: route.query.token,
        uidb64: route.query.uidb64
      })
    },
  {
    path: '/',
    component: () => import('../views/Dashboard.vue'),
    props:true,
    children: [
      {
        path: '',
        component: () => import('../views/DashboardView.vue'),
        props:true
      },
      {
        path: 'categoria-:categoria/:url',
        component: () => import('../views/Fuente_de_dato.vue'),
        props:true

      },
      {
        path: 'admin',
        component: () => import('../views/admin.vue'),
        props:true

      },
    ],
    meta: {
      requiresAuth: true
    },
    
  },
  

  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
});

export default router
