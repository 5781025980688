<template>
	<v-app id="change_password" >
  <v-main style="background-color:#D5D8DC" >
    <v-container  fill-height >
      <v-layout align-center justify-center>
        <v-flex style="max-width:450px" class='text-center display-1 mb-3'>
          <v-card dark color="white" elevation="6">
            <v-card-text class="subheading">
              <v-img
                  class = 'mx-auto mt-4'
                  max-height="45"
                  max-width="181"
                  src="@/media/logo_azul.png"
                  transition="fade"
              ></v-img>
                <br>
                
                <!-- <h3 class="text-center font-weight-light">
                    Recuperar Contraseña
                </h3> -->
                
              <v-form @submit.prevent="change"  ref="form" class="mt-4">
              
                <v-text-field  v-model='password'  light prepend-icon='mdi-lock' label='Contraseña' type='password' :rules="[v => !!v || 'Ingrese una contraseña'] ">
                </v-text-field>
                <v-text-field  v-model='password_confirm'  light prepend-icon='mdi-lock' label='Repita Contraseña' type='password' :rules="[v => !!v || 'Repita la contraseña'] ">
                </v-text-field>
                
                 <div>
                    <v-alert
                    dense
                    type="success"
                    :value="valid_success"
                    transition="fade-transition">             
                    Contraseña actualizada correctamente
                  </v-alert>
                 </div>


                <!-- <div>
                <v-alert type="error" :value="valid" class="text-left" transition="fade-transition">
                  Credenciales incorrectas, intente nuevamente.
                </v-alert>
               </div> -->
                <v-btn color="#1565C0"  link :to="{path: '/login'}" class="mt-4" v-if="valid_success">
                  Ir a login
                </v-btn>
                <v-btn color="#1565C0" block type='submit' class="mt-4" v-else>
                  Cambiar contraseña
                </v-btn>
              </v-form>
             
            </v-card-text>
          </v-card>
          
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
 </v-app>

</template>

<script>
const API_URL = 'https://api.datari.net/auth/password-reset-complete';
import axios from 'axios';


export default {

  name: 'change_password',
  props: ['token','uidb64'],
  data () {
    return {
      valid_success:false,
      valid_fail:false,
      password: null,
      password_confirm:null
    }
  },
  mounted() {
  },
  methods: {
    change() {
      this.$refs.form.validate();
      if (this.password != null && this.password_confirm != null){
        if(this.password === this.password_confirm){
          console.log('validas');
          let password_final = this.password;
          let token_final = this.token;
          let cod = this.uidb64;
          axios({
            method: 'patch',
            url: API_URL,
            data: {
              password: password_final,
              token: token_final,
              uidb64: cod
            }
          })
          this.valid_success = true;
        }
        else{
          console.log('no coinciden')
        }
      } 
    }
  }
}
</script>

<style lang="css" scoped>
</style>