 <template>
 <v-app id="login" >
  <v-main style="background-color:#D5D8DC" >
    <v-container  fill-height >
      <v-layout align-center justify-center>
        <v-flex style="max-width:400px" class='text-center display-1 mb-3' >
          <v-card dark color="white" elevation="6" height=420>
            <v-card-text class="subheading">
              <v-img
                  class = 'mx-auto mt-4'
                  max-height="60"
                  max-width="220"
                  src="@/media/logo_azul.png"
                  transition="fade"
              ></v-img>
              <br>
              <br>
                
              <div style="width:90%; margin: 0 auto;" >
                <v-form @submit.prevent="login" ref='form'>
                  <div id="inputs">
                    <v-text-field  v-model='email' light prepend-icon='mdi-account' label='Email' type='email'  :rules="[v => !!v || 'Ingrese un Email'] " >
                    </v-text-field>
                    <v-text-field  v-model='password' class="mt-4" light prepend-icon='mdi-lock' label='Contraseña' type='password' :rules="[v => !!v || 'Ingrese una contraseña'] "  >
                    </v-text-field>
                  </div>
                  <div id="botones">

                  </div>
                  
                  <v-btn class="mt-6" 
                    :color="error_credenciales == true ? 'red darken-1' : 
                            error_active == true ? 'red darken-1' :
                            login_succesful == true ? 'green darken-1' : '#1565C0'" 
                    block 
                    dark 
                    large
                    type='submit' 
                    transition="fade-transition"
                  >
                    <div v-if="loading_login == true">
                      <v-progress-circular
                        :size="25"
                        :width="3"
                        color="white"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                    <div v-else>
                      <h4 
                        class="font-weight-medium"
                        v-if="error_credenciales"
                      >
                        <v-icon
                          left
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                        Credenciales incorrectas
                      </h4>
                       <h4 
                        class="font-weight-medium"
                        v-else-if="error_active"
                      >
                        <v-icon
                          left
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                        Cuenta desactivada
                      </h4>
                      <h4 
                        class="font-weight-medium"
                        v-else-if="login_succesful"
                      >
                        <v-icon
                          left
                        >
                          mdi-check-circle-outline
                        </v-icon>
                        Credenciales validas
                      </h4>
                      <h4 
                        class="font-weight-medium"
                        v-else
                      >
                        Iniciar Sesión
                      </h4>
                    </div>
                  </v-btn>
                  <v-btn outlined color="primary" small  href="/reset_password" class="mt-3">
                    Recuperar contraseña
                  </v-btn>
                </v-form>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
 </v-app>
 </template>

 <script>

export default {
  name: 'Login',
  data(){
    return {
      email: null,
      password: null,
      error_credenciales: false,
      error_active: false,
      login_succesful: false,
      loading_login:false,
      options: {
      shouldStayLoggedIn: true,
      }
    }
  },
  computed:{
    updateDataEmail(){
      return this.email;
    },
    updateDataPassword(){
      return this.password;
    }
  },
  watch:{
    updateDataEmail(){
      this.loading_login = false;
      this.login_succesful = false;
      this.error_credenciales=false;
      this.error_active = false;

    },

    updateDataPassword(){
      this.loading_login = false;
      this.login_succesful = false;
      this.error_credenciales=false;
      this.error_active = false;
    }
  },


  methods: {
    nuevoInput(){
      console.log(this.email, this.password)
      console.log('olaaas');
    },

    login() {
      this.$refs.form.validate();
      if(this.email != null &&  this.password != null){
        this.loading_login = true;
        let email = this.email;
        let password = this.password;
        this.$refs.form.validate();
        this.sleep(4000).then(() => {
          this.$store.dispatch('login', {email, password})
          .then( () => {
         
            this.loading_login = false;
            this.error_credenciales=false;
            this.error_active = false;
            this.login_succesful = true;
            localStorage.setItem('user',email);
            this.sleep(2000).then(()=>{
              this.$router.push({path: '/'});
            })
          })
          .catch(err => {
            console.log(err)
            this.loading_login = false;
            this.login_succesful = false;
            localStorage.removeItem('user')
            let type_error = err.response.data.detail;
            if(type_error === 'credenciales_invalidas'){
              this.error_credenciales=true;
              this.error_active = false;
            }
            else if(type_error === 'desactivada'){
              this.error_credenciales = false;
              this.error_active = true;
            }
          })
        });
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  },
}
 </script>
