 <template>
 <v-app id="login" >
  <v-main style="background-color:#D5D8DC" >
    <v-container  fill-height >
      <v-layout align-center justify-center>
        <v-flex style="max-width:450px" class='text-center display-1 mb-3'>
          <v-card dark color="white" elevation="6">
            <v-card-text class="subheading">
              <v-img
                  class = 'mx-auto mt-4'
                  max-height="45"
                  max-width="181"
                  src="@/media/logo_azul.png"
                  transition="fade"
              ></v-img>
                <br>
                
                <!-- <h3 class="text-center font-weight-light">
                    Recuperar Contraseña
                </h3> -->
                
              <v-form @submit.prevent="reset"  ref="form">
                <v-text-field  v-model='email' light prepend-icon='mdi-account' label='Email' type='email'  :rules="[v => !!v || 'Ingrese un Email'] " >
                </v-text-field>
                <!-- <v-text-field  v-model='password'  light prepend-icon='mdi-lock' label='Contraseña' type='password' :rules="[v => !!v || 'Ingrese una contraseña'] ">
                </v-text-field> -->
                <!-- <v-checkbox  v-model='options.shouldStayLoggedIn' light label='Recordarme'>
                </v-checkbox> -->
                 <!-- <a href="/reset_password">Recuperar contraseña</a> -->
                

                 <div>
                    <v-alert
                    dense
                    type="info"
                    :value="valid"
                    transition="fade-transition">             
                    Correo enviado <strong>{{email}}</strong>
                  </v-alert>
                 </div>


                <!-- <div>
                <v-alert type="error" :value="valid" class="text-left" transition="fade-transition">
                  Credenciales incorrectas, intente nuevamente.
                </v-alert>
               </div> -->
               
                <v-btn color="#1565C0" block type='submit'>
                  Solicitar nueva contraseña
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
 </v-app>
 </template>

 <script>
const API_URL = 'https://api.datari.net/auth/request-reset-email/';
import axios from 'axios';

export default {
  name: 'Reset',
  data(){
    return {
      email: null,
      valid: false,
      options: {
      shouldStayLoggedIn: true,
      }
    }
  },
  methods: {

    reset() 
    {
        this.$refs.form.validate();
        if(this.email != null){
            let email = this.email;
            axios({
                method: 'post',
                url: API_URL,
                data: {
                    email: email,
                    redirect_url: 'https://datari.net/change_password/'
                }
            });
            this.valid=true;
            
        }

    },

   
  },
}
 </script>