import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		status: '',
		token: localStorage.getItem('token') || null,
		user: {}
	},
	mutations: {
		auth_request(state){
			state.status = 'cargando...'
		},
		auth_success(state,token,user){
			state.status = 'autorizado'
			state.token = token
			state.user = user
		},
		auth_error(state){
			state.status = 'error'
		},
		register_request(state){
			state.status = 'cargando...'
		},
		register_success(state){
			state.status = 'autorizado'
			// state.token = token
			// state.user = user
		},
		register_error(state){
			state.status = 'error'
		},
		logout(state){
			state.status = ''
			state.token = ''
		},
	},
	actions: {
		login({commit},user){
			return new Promise((resolve,reject) => {
				commit('auth_request')
				axios({url:'https://api.datari.net/auth/login/', data: user, method: 'POST'})
				.then(resp => {
					let data_user = resp.data;
					console.log(data_user)
					let is_staff = data_user.is_staff;
					let user_type = data_user.user_type;
					const token = data_user.token['access'];
					console.log(token)
					const usuario = user
					localStorage.setItem('token',token);
					localStorage.setItem('is_staff',is_staff);
					localStorage.setItem('user_type',user_type);
					axios.defaults.headers.common['Authorization'] = "Token " + token
					commit('auth_success', token, usuario)
					resolve(resp)
				})
				.catch(err => {
					commit('auth_error')
					localStorage.removeItem('token')
					localStorage.removeItem('is_staff');
					localStorage.removeItem('user_type');
					reject(err)
				})
			})
		},

		register({commit},user){
			return new Promise((resolve,reject) => {
				commit('register_request')
				axios({url:'https://api.datari.net/auth/register/', data: user, method: 'POST'})
				.then(resp => {
					console.log(resp);
					// let data_user = resp.data;
					// let is_staff = data_user.is_staff;
					// let user_type = data_user.user_type;
					// const token = data_user.tokens['access'];
					// const usuario = user
					// localStorage.setItem('token',token);
					// localStorage.setItem('is_staff',is_staff);
					// localStorage.setItem('user_type',user_type);
					// axios.defaults.headers.common['Authorization'] = "Token " + token
					commit('register_success')
					resolve(resp)
				})
				.catch(err => {
					commit('register_error')
					// localStorage.removeItem('token')
					// localStorage.removeItem('is_staff',is_staff);
					// localStorage.removeItem('user_type',user_type);
					reject(err)
				})	
			})
		},

		logout({commit}){
			return new Promise((resolve) => {
				commit('logout')
				localStorage.removeItem('user')
        		localStorage.removeItem('is_staff')
				localStorage.removeItem('token')
				delete axios.defaults.headers.common['Authorization']
				resolve()
			})
		}
	},
	modules: {
	},
	getters: {
		isLoggedIn: state => !!state.token,
		authStatus: state => state.status

	
	}
})
